import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import heroImageLarge from '../assets/student2.png';
import heroImageLargePlaceholder from '../assets/student2.webp';
import heroImageSmall from '../assets/student2small.png';
import heroImageSmallPlaceholder from '../assets/student2small.webp';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";

const HeroWrapper = styled.section`
  position: relative;
  min-height: 60vh;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  
  @media (max-width: 768px) {
    min-height: 40vh;
    height: 480px;
  }
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: opacity 0.3s ease-in-out;

  @media (max-width: 768px) {
    background-image: url(${props => props.smallSrc});
  }

  @media (min-width: 769px) {
    background-image: url(${props => props.largeSrc});
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); // Adjust this value to control overall darkness
  z-index: 1;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1000px; 
  padding: 4rem 2rem; 
  display: flex;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
`;

const ContentColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  
  padding: 2rem;
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 3rem;
  margin-bottom: 1rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 2.3rem;
    margin-bottom: 0.1rem;
  }

  @media (max-width: 400px) {
    font-size: 1.8rem;
  }
`;

const Subtitle = styled.p`
  color: white;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 400px) {
    font-size: 1rem; 
    margin-bottom: 1.5rem; 
  }
`;

const Button = styled(Link)`
  background-color: #FFD700;
  color: #003366;
  text-decoration: none;
  border: none;
  border-radius: 20px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  align-self: flex-start;
  &:hover {
    background-color: #FFC700;
  }
`;

const HeroSection = ({ onView }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const heroRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onView();
          ReactGA.event({
            category: "Section View",
            action: "View",
            label: "Hero Section"
          });
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    const img = new Image();
    img.src = window.innerWidth <= 768 ? heroImageSmall : heroImageLarge;
    img.onload = () => setImageLoaded(true);

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }
    };
  }, [onView]);

  const handleEnquireClick = () => {
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "Hero Enquire Now"
    });
  };

  return (
    <HeroWrapper ref={heroRef}>
      <HeroBackground 
        smallSrc={heroImageSmallPlaceholder}
        largeSrc={heroImageLargePlaceholder}
        style={{ opacity: imageLoaded ? 0 : 1 }}
      />
      <HeroBackground 
        smallSrc={heroImageSmall}
        largeSrc={heroImageLarge}
        style={{ opacity: imageLoaded ? 1 : 0 }}
      />
      <Overlay />
      <ContentContainer>
        <ContentColumn>
          <Title>Forge your path to Ivy League and Oxbridge</Title>
          <Subtitle>Bespoke mentorship from an Oxbridge and Harvard law alum from Singapore.</Subtitle>
          <Button to="/enquire" onClick={handleEnquireClick}>Enquire Now</Button>
        </ContentColumn>
      </ContentContainer>
    </HeroWrapper>
  );
};

export default HeroSection;