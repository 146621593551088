import React from 'react';
import styled from 'styled-components';

const BannerWrapper = styled.div`
  background-color: #FFD700;
  color: rgba(0,0,0,0.75);
  text-align: center;
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
`;

const TempBanner = () => {
  return (
    <BannerWrapper>
      Currently open for new mentees!
    </BannerWrapper>
  );
};

export default TempBanner;