import React, { useState, useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import styled from 'styled-components';
import { FaWhatsapp, FaTelegram, FaEnvelope, FaUser, FaPhone, FaAt, FaCommentAlt } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactGA from "react-ga4";

const FormPageWrapper = styled.div`
  padding: 3rem 2rem;
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #003366;
  text-align: center;
  margin-bottom: 2.5rem;
  font-family: 'Arial', sans-serif;
  font-size: 2.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputIcon = styled.span`
  position: absolute;
  left: 0.75rem;
  top: 0.75rem;
  color: #003366;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 2.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  font-family: 'Arial', sans-serif;
  transition: border-color 0.3s ease;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #003366;
    box-shadow: 0 0 0 2px rgba(0, 51, 102, 0.2);
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  .react-tel-input .form-control {
    width: 100%;
    padding: 0.75rem 0.75rem 0.75rem 2.5rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    height: auto;
    font-family: 'Arial', sans-serif;
    box-sizing: border-box;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 2.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 150px;
  font-size: 1rem;
  font-family: 'Arial', sans-serif;
  transition: border-color 0.3s ease;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #003366;
    box-shadow: 0 0 0 2px rgba(0, 51, 102, 0.2);
  }
`;

const ConsentLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #555;
`;

const ConsentCheckbox = styled.input`
  margin-right: 10px;
  transform: scale(1.2);
`;

const SubmitButton = styled.button`
  background-color: #FFD700;
  color: #003366;
  border: none;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #FFC700;
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const AlternativeContactOptions = styled.div`
  margin-top: 2.5rem;
  text-align: center;
`;

const ContactButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContactButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  color: #ffffff;
  text-decoration: none;
  border-radius: 25px;
  transition: all 0.3s ease;
  font-weight: bold;
  width: 140px;

  @media (max-width: 480px) {
    width: 30%;
    margin-bottom: 0.5rem;
  }

  &:hover {
    opacity: 0.9;
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

const WhatsAppButton = styled(ContactButton)`
  background-color: #25D366;
`;

const TelegramButton = styled(ContactButton)`
  background-color: #0088cc;
`;

const EmailButton = styled(ContactButton)`
  background-color: #D44638;
`;

const Icon = styled.span`
  margin-right: 8px;
  font-size: 1.2rem;
`;

const FormPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    consent: false,
  });

  const [state, handleSubmit] = useForm("mjkbkwev");

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData(prevData => ({
      ...prevData,
      phone: value
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
    
    ReactGA.event({
      category: "Form",
      action: "Submit",
      label: "Enquiry Form"
    });
  };

  useEffect(() => {
    if (state.succeeded) {
      alert("Thanks for your submission!");
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
        consent: false,
      });

      ReactGA.event({
        category: "Form",
        action: "Submit Success",
        label: "Enquiry Form"
      });
    }
  }, [state.succeeded]);

  const trackDirectContact = (method) => {
    ReactGA.event({
      category: "Contact",
      action: "Click",
      label: method
    });
  };

  return (
    <FormPageWrapper>
      <Title>Get in Touch</Title>
      <Form onSubmit={onSubmit}>
        <InputWrapper>
          <InputIcon><FaUser /></InputIcon>
          <Input 
            type="text" 
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Your Name" 
            required 
            onFocus={() => ReactGA.event({
              category: "Form",
              action: "Focus",
              label: "Name Field"
            })}
          />
        </InputWrapper>
        <InputWrapper>
          <InputIcon><FaPhone /></InputIcon>
          <StyledPhoneInput
            country={'sg'}
            value={formData.phone}
            onChange={handlePhoneChange}
            inputProps={{
              name: 'phone',
              required: true,
              autoFocus: false,
              onFocus: () => ReactGA.event({
                category: "Form",
                action: "Focus",
                label: "Phone Field"
              })
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <InputIcon><FaAt /></InputIcon>
          <Input 
            type="email" 
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Your Email" 
            required 
            onFocus={() => ReactGA.event({
              category: "Form",
              action: "Focus",
              label: "Email Field"
            })}
          />
        </InputWrapper>
        <InputWrapper>
          <InputIcon><FaCommentAlt /></InputIcon>
          <TextArea 
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            placeholder="Your Message" 
            required 
            onFocus={() => ReactGA.event({
              category: "Form",
              action: "Focus",
              label: "Message Field"
            })}
          />
        </InputWrapper>
        <ConsentLabel>
          <ConsentCheckbox 
            type="checkbox" 
            name="consent"
            checked={formData.consent}
            onChange={(e) => {
              handleInputChange(e);
              ReactGA.event({
                category: "Form",
                action: e.target.checked ? "Consent Given" : "Consent Removed",
                label: "Consent Checkbox"
              });
            }}
            required 
          />
          I consent to having this website store my submitted information.
        </ConsentLabel>
        {state.submitting ? (
          <p>Submitting...</p>
        ) : (
          <SubmitButton 
            type="submit" 
            disabled={state.submitting}
            onClick={() => ReactGA.event({
              category: "Form",
              action: "Submit Attempt",
              label: "Enquiry Form"
            })}
          >
            Send Message
          </SubmitButton>
        )}
        <ValidationError 
          prefix="Form" 
          field="form"
          errors={state.errors}
        />
      </Form>
      <AlternativeContactOptions>
        <h3>Or reach us directly via:</h3>
        <ContactButtonsWrapper>
          <WhatsAppButton 
            href="https://wa.me/+6596705088" 
            target="_blank" 
            rel="noopener noreferrer"
            onClick={() => ReactGA.event({
              category: "Contact",
              action: "Click",
              label: "WhatsApp"
            })}
          >
            <Icon><FaWhatsapp /></Icon> WhatsApp
          </WhatsAppButton>
          <TelegramButton 
            href="https://t.me/iamazfer" 
            target="_blank" 
            rel="noopener noreferrer"
            onClick={() => ReactGA.event({
              category: "Contact",
              action: "Click",
              label: "Telegram"
            })}
          >
            <Icon><FaTelegram /></Icon> Telegram
          </TelegramButton>
          <EmailButton 
            href="mailto:hello@khanadmissions.com"
            onClick={() => ReactGA.event({
              category: "Contact",
              action: "Click",
              label: "Email"
            })}
          >
            <Icon><FaEnvelope /></Icon> Email
          </EmailButton>
        </ContactButtonsWrapper>
      </AlternativeContactOptions>
    </FormPageWrapper>
  );
};

export default FormPage;