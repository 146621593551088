import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';
import Header from './components/Header';
import LandingPage from './pages/LandingPage';
import FormPage from './pages/FormPage';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import CookiePreferences from './pages/CookiePreferences';
import DataProtectionNotice from './pages/DataProtection';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import ReactGA from 'react-ga4';

const StyledCookieConsent = styled(CookieConsent)`
  background: rgba(0, 51, 102, 0.95) !important;
  color: #ffffff !important;
  padding: 1rem !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 1rem !important;

  @media (min-width: 768px) {
    flex-direction: row !important;
  }

  .cookie-text {
    flex: 1;
    text-align: center;
    margin-bottom: 0.5rem;

    @media (min-width: 768px) {
      text-align: left;
      margin-bottom: 0;
    }
  }
`;

const Button = styled.button`
  background: #FFD700;
  color: #003366;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <PageTracker />
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/enquire" element={<FormPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/cookie-preferences" element={<CookiePreferences />} />
          <Route path="/data-protection" element={<DataProtectionNotice />} />
        </Routes>
        <Footer />
        <StyledCookieConsent
          location="bottom"
          buttonText="Acknowledge"
          cookieName="myWebsiteCookieConsent"
          expires={150}
        >
          <div className="cookie-text">
            This website uses cookies to enhance your experience. By continuing to use this site, you acknowledge our use of cookies.
          </div>
        </StyledCookieConsent>
      </div>
    </Router>
  );
};

const PageTracker = () => {
  usePageTracking();
  return null;
};

export default App;
