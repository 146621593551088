import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { FaShieldAlt, FaUserSecret, FaLock, FaGlobe, FaUsers, FaShareAlt, FaServer, FaUserCog } from 'react-icons/fa';

const PageWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem 2rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #003366;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  position: relative;
  
  &:after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: #FFD700;
    margin: 1rem auto 0;
  }
`;

const Section = styled.section`
  margin-bottom: 3rem;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const SectionTitle = styled.h2`
  color: #003366;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
`;

const Icon = styled.span`
  margin-right: 10px;
  color: #FFD700;
`;

const Paragraph = styled.p`
  line-height: 1.8;
  margin-bottom: 1rem;
  color: #333;
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 1.5rem;
`;

const ListItem = styled.li`
  position: relative;
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  font-size: 1.05rem;
  line-height: 1.9;

  &:before {
    content: "•";
    color: #003366;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    top: 0;
    line-height: 1.6;
  }
`;

const PrivacyPolicy = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>Privacy Policy | Khan Admissions</title>
        <meta name="description" content="Read our privacy policy to understand how Khan Admissions collects, uses, and protects your personal information when you use our services." />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Privacy Policy",
              "description": "Khan Admissions privacy policy detailing how we handle your personal information.",
              "url": "https://khanadmissions.com/privacy-policy"
            }
          `}
        </script>
      </Helmet>
      
      <Title>Privacy Policy</Title>
      
      <Section>
        <SectionTitle>
          <Icon><FaGlobe /></Icon>
          Introduction
        </SectionTitle>
        <Paragraph>This policy applies across all websites that we own and operate, all apps and other platforms we use and all services we provide ("Services"). Our website is currently hosted at khanadmissions.com.</Paragraph>
        <Paragraph>This policy applies to identifiable information about you like your name, email, address, phone number, bank account details, payment information and so on ("Personal Data"). If you can't be identified (for example, when Personal Data has been aggregated and anonymised) then this policy doesn't apply.</Paragraph>
        <Paragraph>We may need to update this policy from time to time as we undertake new Personal Data practices or adopt new privacy policies. We reserve the right to amend this Privacy Policy and Terms of Use at any time, for any reason, without notice to you, other than the posting of the amended Privacy Policy and Terms of Use at our Website. You should check our Website frequently to see the current Privacy Policy and Terms of Use that are in effect and any changes that may have been made to them.</Paragraph>
      </Section>

      <Section>
        <SectionTitle>
          <Icon><FaUsers /></Icon>
          Who are 'we'?
        </SectionTitle>
        <Paragraph>When we refer to 'we' (or 'our' or 'us'), that means Khan Admissions Pte Ltd and our subsidiaries. We may also refer to ourselves as 'Khan Admissions'. Our headquarters are in Singapore.</Paragraph>
      </Section>

      <Section>
        <SectionTitle>
          <Icon><FaUserSecret /></Icon>
          How we collect your data
        </SectionTitle>
        <Paragraph>When you visit our Website or use our Services, we collect Personal Data. The ways we collect it can be broadly categorised into the following:</Paragraph>
        <List>
          <ListItem>Information you provide to us directly: When you visit or use some parts of our Website and use our Services we might ask you to provide Personal Data to us. For example, we ask for your contact information when you register for one of our events, fill out any forms on our Website, participate in seminars or webinars with us, apply for a job with us or contact us with questions/ request support. If you don't want to provide us with Personal Data, you don't have to, but it might mean you cant use some parts of our Website or Services.</ListItem>
          <ListItem>Information we collect automatically: We collect some information about you automatically when you visit our Website or use our Services, which may include your internet protocol (IP) address, region, or general location where your computer or device is accessing the internet, browser type, operating system and device type. We also collect information when you navigate through our Website and Services, including what pages you looked at and what links you clicked on. This information is useful for us as it helps us get a better understanding of how you're using our Website and Services so that we can continue to provide the best experience possible (for example, by personalising the content you see). Some of this information is collected using cookies, web beacons and similar tracking technologies.</ListItem>
          <ListItem>Information we get from third parties: The majority of information we collect, we collect directly from you. Sometimes we might collect Personal Data about you from other sources, such as publicly available materials or trusted third parties like our marketing and research partners. We use this information to supplement the Personal Data we already hold about you, in order to better inform, personalise and improve our Services, and to validate the Personal Data you provide.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>
          <Icon><FaShieldAlt /></Icon>
          How we use your data
        </SectionTitle>
        <Paragraph>We may use your Personal Data to:</Paragraph>
        <List>
          <ListItem>Operate our Website;</ListItem>
          <ListItem>Help diagnose problems with our server and to administer our Website, analyse trends, track visitor movements, and gather broad demographic information that assists us in identifying visitor preferences and designing our Website to better suit our users' needs;</ListItem>
          <ListItem>Help Khan Admissions understand our students and their guardians needs and interests to better tailor our Services to meet your needs;</ListItem>
          <ListItem>Provide you with information about our events, information or Khan Admissions-related Services;</ListItem>
          <ListItem>Manage our relationship with you;</ListItem>
          <ListItem>Detect and prevent fraudulent activity and make sure everyone is using our Website and Services fairly and in accordance with our Terms of Use;</ListItem>
          <ListItem>Send you text messages (message and data rates may apply, message frequency varies); and</ListItem>
          <ListItem>Carry out any other legitimate purpose.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>
          <Icon><FaShareAlt /></Icon>
          How we can share your data
        </SectionTitle>
        <Paragraph>Khan Admissions may enter into arrangements with third parties in which such third parties will promote Khan Admissions' Services on their website. If you are interested in such Services, you will be asked to share Personal Data to receive more information about the Services. In some cases, the third party will collect Personal Data and provide it to Khan Admissions at your direction. In other cases, Khan Admissions will collect Personal Data from you directly and provide it to such third party, in which case the third party may use your Personal Data in accordance with their privacy policy as though you had provided it directly to such third party. Your Personal Data will be subject to such third party's privacy policy as well as Khan Admissions' privacy policy.</Paragraph>
        <Paragraph>Khan Admissions' Website use interfaces with social media sites such as Facebook, Instagram, Twitter and others. If you choose to "like" or share information from Khan Admissions' Website through these services, you should review the privacy policy of that service. If you are a member of a social media site, the interfaces may allow the social media site to connect your site visit to your Personal Data.</Paragraph>
        <Paragraph>Additional to the above, we will only disclose information to third parties in the following cases:</Paragraph>
        <List>
          <ListItem>as required by law, such as to comply with a subpoena or similar legal process</ListItem>
          <ListItem>when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, to investigate fraud, or to respond to a government request</ListItem>
          <ListItem>if we are involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice through our app of any change in ownership or uses of your Personal Data, as well as any choices you may have regarding your Personal Data</ListItem>
          <ListItem>to any other third party with your prior consent to do so.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>
          <Icon><FaGlobe /></Icon>
          International
        </SectionTitle>
        <Paragraph>When we share data, it may be transferred to, and processed in, countries other than the country you live in – such as any country where our offices are located. These countries may have laws different to what you're used to. Rest assured, where we disclose Personal Data to a third party in another country, we put safeguards in place to ensure your Personal Data remains protected.</Paragraph>
      </Section>

      <Section>
        <SectionTitle>
          <Icon><FaLock /></Icon>
          Security
        </SectionTitle>
        <Paragraph>To help protect the privacy of data and personally identifiable information you transmit through use of our Website, we maintain physical, technical and administrative safeguards. We update and test our security technology on an ongoing basis. We restrict access to your Personal Data to those employees who need to know that information to provide benefits or Services to you. In addition, our employees are aware of the importance of confidentiality and maintaining the privacy and security of your information. We commit to taking appropriate disciplinary measures to enforce our employees' privacy responsibilities.</Paragraph>
        <Paragraph>No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee the absolute security of your information.</Paragraph>
      </Section>

      <Section>
        <SectionTitle>
          <Icon><FaServer /></Icon>
          Retention
        </SectionTitle>
        <Paragraph>Your Personal Data is stored by Khan Admissions on its servers, and on the servers of the cloud-based database management services Khan Admissions engages, these services may be stored across the globe and are managed and maintained by their professional teams. Khan Admissions retains data for the duration of your relationship with Khan Admissions and for a period of time thereafter to allow you to recommence your relationship with Khan Admissions, to analyse the data for Khan Admissions' own operations, and for historical and archiving purposes (for example, to comply with applicable legal, tax and accounting requirements). For more information on where and how long your Personal Data is stored, and for more information on your rights of erasure and portability, please contact us at the address below.</Paragraph>
      </Section>

      <Section>
        <SectionTitle>
          <Icon><FaUserCog /></Icon>
          Your rights
        </SectionTitle>
        <Paragraph>It's your Personal Data and you have certain rights relating to it. Khan Admissions, from time-to-time, may send you marketing communications using the Personal Data you provide to us. You can ask us not to send you marketing communications at any time by making a request by emailing hello@khanadmissions.com.</Paragraph>
        <Paragraph>You also have rights to:</Paragraph>
        <List>
          <ListItem>know what Personal Data we hold about you, and to make sure it's correct and up to date;</ListItem>
          <ListItem>request a copy of your Personal Data, or ask us to restrict processing your Personal Data or delete it; and</ListItem>
          <ListItem>object to our continued processing of your Personal Data.</ListItem>
        </List>
        <Paragraph>You can exercise these rights at any time by contacting us at the address below. Khan Admissions will answer your request within a reasonable timeframe. If the request cannot be met within a reasonable time frame, Khan Admissions will provide you with a date when the information will be provided. If for some reason the request is denied, Khan Admissions will provide an explanation as to why it has been denied.</Paragraph>
      </Section>

      <Section>
        <SectionTitle>
          <Icon><FaUsers /></Icon>
          How to contact us
        </SectionTitle>
        <Paragraph>If you would like to find out about what Personal Data we hold about you, exercise your rights, or you have a question or feedback for us on this policy, our Website or Services, please get in touch via hello@khanadmissions.com.</Paragraph>
        <Paragraph>Khan Admissions is not affiliated or associated with any university, college, or education institution mentioned on this website.</Paragraph>
        <Paragraph>Last updated: 14 July 2024</Paragraph>
      </Section>
    </PageWrapper>
  );
};

export default PrivacyPolicy;