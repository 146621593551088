import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { Link, useLocation } from 'react-router-dom';
import logoSvg from '../assets/KhanAdmissions.svg';

const HeaderWrapper = styled.header`
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white; // Add a background color to ensure content doesn't show through
  z-index: 1000; // Ensure the header stays on top of other content
`;

const HeaderSpacer = styled.div`
  height: ${props => props.height}px;
`;

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const headerHeight = document.querySelector('header').offsetHeight;
    const yOffset = -headerHeight;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  };

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const LogoImage = styled.img`
  height: 40px; // Adjust this value to fit your design
  width: auto;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const NavItems = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;

  @media (max-width: 860px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.95);
    width: 100%;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
    gap: 0.5rem;
  }
`;

const CenteredNavItems = styled.div`
  display: flex;
  gap: 1.5rem;

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
  }
`;

const NavLink = styled(HashLink)`
  color: #003366;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #FFD700;
    transition: width 0.3s ease;
  }

  &:hover {
    color: #FFD700;
    &::after {
      width: 100%;
    }
  }

  @media (max-width: 860px) {
    padding: 0.5rem 0;
    width: 100%;
    text-align: center;
  }
`;

const EnquireButton = styled(Link)`
  background-color: #FFD700;
  color: #003366;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  &:hover {
    background-color: #FFC700;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }

  @media (max-width: 860px) {
    margin-top: 0.5rem;
    width: 100%;
    text-align: center;
  }
`;

const HamburgerButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #003366;
  transition: color 0.3s ease;

  &:hover {
    color: #FFD700;
  }

  @media (max-width: 860px) {
    display: block;
  }
`;

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(0);
    const headerRef = useRef(null);
    const location = useLocation();
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > 768) {
          setIsOpen(false);
        }
        if (headerRef.current) {
          setHeaderHeight(headerRef.current.offsetHeight);
        }
      };
      
      handleResize(); // Call once to set initial height
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const toggleMenu = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen(!isOpen);
    };
  
    const closeMenu = () => setIsOpen(false);
  
    const handleLogoClick = (event) => {
      const { pathname } = location;
      if (pathname === '/') {
        event.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };

    return (
      <>
        <HeaderWrapper ref={headerRef}>
        <Nav>
          <LogoLink to="/" onClick={handleLogoClick}>
              <LogoImage src={logoSvg} alt="Khan Admissions Logo" />
          </LogoLink>
        <HamburgerButton onClick={toggleMenu} aria-label="Toggle menu">
          {isOpen ? '✕' : '☰'}
        </HamburgerButton>
        <NavItems isOpen={isOpen}>
          <CenteredNavItems>
            <NavLink to="/about-us" onClick={closeMenu}>About Us</NavLink>
          <NavLink smooth to="/#services" scroll={scrollWithOffset} onClick={closeMenu}>Services</NavLink>
            <NavLink smooth to="/#why-us" scroll={scrollWithOffset} onClick={closeMenu}>Why Us</NavLink>
            <NavLink smooth to="/#waiver-programme" scroll={scrollWithOffset} onClick={closeMenu}>Waiver Programme</NavLink>
            <NavLink smooth to="/#faq" scroll={scrollWithOffset} onClick={closeMenu}>FAQ</NavLink>
          </CenteredNavItems>
          <EnquireButton to="/enquire" onClick={closeMenu}>ENQUIRE NOW</EnquireButton>
        </NavItems>
      </Nav>
        </HeaderWrapper>
        <HeaderSpacer height={headerHeight} />
      </>
    );
  };

export default Header;