import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: #333;
  color: white;
  padding: 1rem;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const FooterColumn = styled.div`
  flex: 1;
  min-width: 200px;
  margin: 1rem 0;
`;

const FooterTitle = styled.h4`
  margin-bottom: 1rem;
`;

const FooterLink = styled.a`
  display: block;
  color: white;
  text-decoration: none;
  margin-bottom: 0.5rem;
  &:hover {
    text-decoration: underline;
  }
`;

{/* const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SocialLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  &:hover {
    color: #FFD700;
  }
`; */}

const FooterBottom = styled.div`
  border-top: 1px solid #444;
  padding-top: 1rem;
  text-align: center;
  font-size: 0.9rem;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <FooterColumn>
          <FooterTitle>About Us</FooterTitle>
          <FooterLink href="/about-us">Our Dedicated Mentor</FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterTitle>Privacy & Legal</FooterTitle>
          <FooterLink href="/privacy-policy">Privacy Policy</FooterLink>
          <FooterLink href="/data-protection">Data Protection Notice</FooterLink>
          <FooterLink href="/terms-and-conditions">Terms & Conditions</FooterLink>
          <FooterLink href="/cookie-preferences">Cookie Preferences</FooterLink>
        </FooterColumn>
        {/* <FooterColumn>
          <FooterTitle>Follow Us</FooterTitle>
          <SocialLinks>
            <SocialLink href="#"><i className="fab fa-facebook-f"></i></SocialLink>
            <SocialLink href="#"><i className="fab fa-twitter"></i></SocialLink>
            <SocialLink href="#"><i className="fab fa-linkedin-in"></i></SocialLink>
            <SocialLink href="#"><i className="fab fa-instagram"></i></SocialLink>
          </SocialLinks>
          </FooterColumn> */}
      </FooterContent> 
      <FooterBottom>
        <p>&copy; 2024 Khan Admissions Pte Ltd. All rights reserved.</p>
        <p>We are not affiliated with or associated with any university, college, or any other educational institution mentioned on this website.</p>
      </FooterBottom>
    </FooterWrapper>
  );
};

export default Footer;
