import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { FaCookie, FaList, FaCookieBite, FaCog, FaCheckCircle, FaQuestionCircle, FaHistory } from 'react-icons/fa';

const PageWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem 2rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #003366;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  position: relative;
  
  &:after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: #FFD700;
    margin: 1rem auto 0;
  }
`;

const Section = styled.section`
  margin-bottom: 3rem;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const SectionTitle = styled.h2`
  color: #003366;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
`;

const Icon = styled.span`
  margin-right: 10px;
  color: #FFD700;
`;

const Paragraph = styled.p`
  line-height: 1.8;
  margin-bottom: 1rem;
  color: #333;
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 1.5rem;
`;

const ListItem = styled.li`
  position: relative;
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  font-size: 1.05rem;
  line-height: 1.9;

  &:before {
    content: "•";
    color: #003366;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    top: 0;
    line-height: 1.6;
  }
`;

const CookiePreferences = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>Cookie Preferences | Khan Admissions</title>
        <meta name="description" content="Manage your cookie preferences and learn about the types of cookies used on the Khan Admissions website." />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Cookie Preferences",
              "description": "Manage cookie preferences and learn about cookies used on Khan Admissions website.",
              "url": "https://khanadmissions.com/cookie-preferences"
            }
          `}
        </script>
      </Helmet>
      
  <Title>Cookie Preferences</Title>

  <Section>
    <SectionTitle>
      <Icon><FaCookie /></Icon>
      What are Cookies?
    </SectionTitle>
    <Paragraph>Cookies are small text files that are placed on your device by websites that you visit. They are widely used to make websites work, or work more efficiently, as well as to provide information to the owners of the site.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaList /></Icon>
      Types of Cookies We Use
    </SectionTitle>
    <Paragraph>We use the following types of cookies on our website:</Paragraph>
    <List>
      <ListItem><strong>Essential Cookies:</strong> These cookies are necessary for the website to function and cannot be switched off in our systems.</ListItem>
      <ListItem><strong>Performance Cookies:</strong> These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. We use Google Analytics for this purpose.</ListItem>
      <ListItem><strong>Functionality Cookies:</strong> These cookies enable the website to provide enhanced functionality and personalization.</ListItem>
      <ListItem><strong>Targeting Cookies:</strong> These cookies may be set through our site by our advertising partners to build a profile of your interests and show you relevant ads on other sites.</ListItem>
    </List>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaCookieBite /></Icon>
      Specific Cookies Used
    </SectionTitle>
    <Paragraph>Here are the specific cookies we use on our site:</Paragraph>
    <List>
      <ListItem><strong>Google Analytics:</strong> Used to collect information about how visitors use our site. We use this information to compile reports and to help us improve the site. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the site from, and the pages they visited.</ListItem>
      <ListItem><strong>Session Cookie:</strong> This cookie is essential for the proper functioning of our website. It maintains your session while you navigate our site.</ListItem>
      <ListItem><strong>Preference Cookie:</strong> This functionality cookie remembers your preferences (such as language settings) to enhance your browsing experience.</ListItem>
    </List>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaCog /></Icon>
      Managing Cookies
    </SectionTitle>
    <Paragraph>You can manage or disable cookies by adjusting your browser settings. Please note that disabling cookies may affect the functionality of our website.</Paragraph>
    <Paragraph>To learn more about how to manage cookies, visit <a href="https://www.aboutcookies.org/" target="_blank" rel="noopener noreferrer">AboutCookies.org</a>.</Paragraph>
    <Paragraph>You can also use our cookie consent tool to manage your preferences:</Paragraph>
    {/* Add your cookie consent management tool here */}
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaCheckCircle /></Icon>
      Consent
    </SectionTitle>
    <Paragraph>By using our website, you consent to our use of cookies in accordance with this Cookie Preferences page. You can withdraw your consent at any time by adjusting your browser settings to refuse cookies.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaQuestionCircle /></Icon>
      Questions or Concerns
    </SectionTitle>
    <Paragraph>If you have any questions or concerns about our use of cookies, please contact us at <a href="mailto:hello@khanadmissions.com">hello@khanadmissions.com</a>.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaHistory /></Icon>
      Updates to this Policy
    </SectionTitle>
    <Paragraph>We may update this Cookie Preferences page from time to time. Any changes will be posted on this page with an updated revision date. Please check back periodically to stay informed about our use of cookies.</Paragraph>
    <Paragraph>Last updated: 14 July 2024</Paragraph>
  </Section>
</PageWrapper>
  );
};

export default CookiePreferences;