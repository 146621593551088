import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import phoneImage from '../assets/phone1.png';
import certImage from '../assets/cert.png';
import gatesImage from '../assets/gates.png';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";

const ServicesWrapper = styled.section`
  background-color: #F5F5F5;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23003366' fill-opacity='0.02' fill-rule='evenodd'/%3E%3C/svg%3E");
  padding: 2rem 1rem;
`;

const Title = styled.h2`
  color: #003366;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const ServiceCard = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

const ServiceTitle = styled.h3`
  color: #003366;
  margin-bottom: 1rem;
`;

const ServiceDescription = styled.p`
  margin-bottom: 1rem;
  flex-grow: 1;
`;

const FineText = styled.p`
  font-size: 10px;
  margin-bottom: 0.8rem;
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
  display: flex;
  margin-top: 0.5rem;
  justify-content: center;
`;

const EnquireNow = styled(Link)`
  background-color: #FFD700;
  color: #003366;
  text-decoration: none;
  border: none;
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #FFC700;
  }
`;

const ServicesOffered = ({ onView }) => {
  const servicesRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onView();
          ReactGA.event({
            category: "Section View",
            action: "View",
            label: "Services Offered"
          });
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (servicesRef.current) {
      observer.observe(servicesRef.current);
    }

    return () => {
      if (servicesRef.current) {
        observer.unobserve(servicesRef.current);
      }
    };
  }, [onView]);

  const handleEnquireClick = (service) => {
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: `Enquire Now - ${service}`
    });
  };

  return (
    <ServicesWrapper id="services" ref={servicesRef}>
      <Title>Services Offered</Title>
      <ServiceGrid>
        <ServiceCard>
          <ServiceImage src={phoneImage} alt="Ad-hoc Consult"/>
          <ServiceTitle>Ad-hoc Consultation</ServiceTitle>
          <ServiceDescription>Unlock your academic potential with a focused and personalized ad-hoc consulting session. In just one hour, gain critical insights and strategic advice tailored to your unique needs.</ServiceDescription>
          <FineText>* Fees waived if you then sign up for the full package.</FineText>
          <ButtonWrapper>
            <EnquireNow to="/enquire" onClick={() => handleEnquireClick("Ad-hoc Consultation")}>Enquire Now</EnquireNow>
          </ButtonWrapper>
        </ServiceCard>
        <ServiceCard>
          <ServiceImage src={certImage} alt="Scholarship Consult" />
          <ServiceTitle>Scholarship Consultation</ServiceTitle>
          <ServiceDescription>Navigate complex scholarship application processes with ease. In just one hour, identify the best scholarship opportunities tailored to your unique background and achievements.</ServiceDescription>
          <FineText>* Fees waived if you then sign up for the full package.</FineText>
          <ButtonWrapper>
            <EnquireNow to="/enquire" onClick={() => handleEnquireClick("Scholarship Consultation")}>Enquire Now</EnquireNow>
          </ButtonWrapper>
        </ServiceCard>
        <ServiceCard>
          <ServiceImage src={gatesImage} alt="Full Package" />
          <ServiceTitle>Full Package</ServiceTitle>
          <ServiceDescription>Forge your path to the university of your dreams with personalized mentorship and strategic insights. Crafted to provide thorough support from start to finish, this complete package ensures every aspect of your application reflects who you truly are in a highly competitive admissions landscape.</ServiceDescription>
          <ButtonWrapper>
            <EnquireNow to="/enquire" onClick={() => handleEnquireClick("Full Package")}>Enquire Now</EnquireNow>
          </ButtonWrapper>
        </ServiceCard>
      </ServiceGrid>
    </ServicesWrapper>
  );
};

export default ServicesOffered;