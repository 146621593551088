import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BannerWrapper = styled.div`
  background-color: #003366;
  background-image: linear-gradient(135deg, #003366 0%, #001a33 100%);
  color: white;
  text-align: center;
  padding: 4rem 1rem;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 80%);
    transform: rotate(30deg);
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
`;

const Subtitle = styled.p`
  margin-bottom: 2rem;
  font-size: 1.2rem;
  opacity: 0.9;
`;

const Button = styled(Link)`
  background-color: #FFD700;
  color: #003366;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  text-decoration: none;  /* Remove underline */

  &:hover {
    background-color: #FFC700;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0,0,0,0.15);
  }
`;

const EndBanner = () => {
  return (
    <BannerWrapper>
      <Content>
        <Title>Ready to Start Your Journey?</Title>
        <Subtitle>Take the first step towards your dream university today.</Subtitle>
        <Button to="/enquire">Enquire Now</Button>
      </Content>
    </BannerWrapper>
  );
};

export default EndBanner;
