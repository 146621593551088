import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";

const FAQWrapper = styled.section`
  padding: 2rem 1rem;
`;

const Title = styled.h2`
  color: #003366;
  text-align: center;
  margin-bottom: 2rem;
`;

const FAQList = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const FAQItem = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
`;

const QuestionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Question = styled.h3`
  color: #003366;
  margin-bottom: 0.5rem;
  font-weight: ${props => props.isActive ? 'bold' : 'normal'};
`;

const Arrow = styled.span`
  transition: transform 0.3s ease;
  transform: ${props => props.isActive ? 'rotate(180deg)' : 'rotate(0)'};
  color: ${props => props.isActive ? '#FFC700' : '#999'};
`;

const Answer = styled.p`
  color: #333;
  display: ${props => props.isVisible ? 'block' : 'none'};
  margin-top: 0.5rem;
`;

const MiniHeader = styled.h3`
  color: #003366;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const FAQ = ({ onView }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const faqRef = useRef(null);

  useEffect(() => {
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onView();
          ReactGA.event({
            category: "Section View",
            action: "View",
            label: "FAQ"
          });
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (faqRef.current) {
      observer.observe(faqRef.current);
    }

    return () => {
      if (faqRef.current) {
        observer.unobserve(faqRef.current);
      }
    };
  }, [onView]);

  const toggleQuestion = (index) => {
    setActiveIndex(prevIndex => {
      const newIndex = prevIndex === index ? null : index;
      if (newIndex !== null) {
        ReactGA.event({
          category: "FAQ",
          action: "Open Question",
          label: index
        });
      }
      return newIndex;
    });
  };

    const faqData = {
        adHocConsulting: [
          { 
            question: "What is Ad-Hoc Consultation?", 
            answer: "An Ad-Hoc Consultation is a flexible, one-hour session designed to address specific questions or challenges you have about the admissions process. Whether you need help with application strategy, essay review, or interview preparation, this service provides targeted advice and support tailored to your immediate needs." 
          },
          { 
            question: "How does an Ad-Hoc Consultation work?", 
            answer: "During an ad-hoc consultation session, you'll receive personalized guidance from our expert consultant. Simply schedule a session, outline your questions or topics of concern, and we'll provide actionable insights and advice to help you move forward with confidence." 
          },
          { 
            question: "Who should consider an Ad-Hoc Consultation?", 
            answer: "An Ad-Hoc Consultation is ideal for students who have specific concerns or questions about their applications and need immediate, expert advice. It's perfect for those who are confident in most areas but need targeted support on particular aspects." 
          },
          { 
            question: "What topics can be covered in an Ad-Hoc Consultation session?", 
            answer: "You can cover a wide range of topics in an Ad-Hoc Consultation session, including application strategy, essay writing, interview preparation, school selection, and any other specific concerns you have about the admissions process." 
          },
        ],
        scholarshipConsulting: [
          { 
            question: "What scholarship services do you offer?", 
            answer: "Our scholarship consultation service is designed to help you identify appropriate scholarships based on your ambition, background and interests. We provide guidance on finding scholarships that match your profile, key pointers for crafting compelling applications, and tips on preparing for scholarship interviews." 
          },
          { 
            question: "How can you help me win scholarships?", 
            answer: "We cannot help anyone to 'win' scholarships, unfortunately. We assist you in making a more considered decision about the opportunities available for you to make a more considered choice." 
          },
          { 
            question: "What types of scholarships do you assist with?", 
            answer: "We primarily assist with scholarships either offered by Oxbridge law or Ivy League universities, or available to Singaporeans. For those not falling into these categories, please check in with us." 
          },
          { 
            question: "What is the success rate of your scholarship consulting services?", 
            answer: "We don't measure success rates for scholarship consultations - they are very person-specific, and also dependent on the funding authority's general criteria." 
          },
        ],
        fullPackage: [
            { 
                question: "What univerisities should I be applying to if I intend to take the full package?", 
                answer: "We specialize in providing consultations for Oxbridge Law (and by extension UK Law), or for Ivy League and top US univeristies. If these are not your target or reach universities, then our service may not be appropriate for you." 
              },
            { 
            question: "What's included in the Full Package?", 
            answer: "The Full Package includes comprehensive support throughout the entire admissions process. This service covers everything from initial consultation and school selection to essay writing, interview preparation, and final application review. We provide continuous support and personalized guidance to ensure you present the strongest application possible." 
          },
          { 
            question: "How long does the Full Package consulting last?", 
            answer: "The duration of the Full Package consulting varies depending on your needs and application timelines. Typically, this service spans several months, ensuring you have support from the initial stages of application planning through to the submission of your applications. The earlier you get the Full Package, the better guidance we will be able to give." 
          },
          { 
            question: "How do you tailor the Full Package to individual needs?", 
            answer: "The Full Package is personalized to each student's unique background, strengths, and goals. We begin with a comprehensive assessment to understand your needs and then develop a customized plan that addresses all aspects of your application." 
          },
          { 
            question: "What kind of support can I expect during the essay writing process?", 
            answer: "We provide detailed feedback and guidance throughout the essay writing process, from brainstorming and ideation to final edits. Our goal is to help you craft compelling essays that effectively showcase your unique experiences and strengths. Please note that we do not under any circumstances write essays on behalf of students (as it is very unethical)." 
          },
          { 
            question: "Do you offer interview preparation as part of the Full Package?", 
            answer: "Yes, interview preparation is a key component of the Full Package. We provide mock interviews, feedback, and tips to help you confidently and effectively present yourself during admissions interviews." 
          },
          { 
            question: "Can you help with applications to both US and UK universities?", 
            answer: "Absolutely. Our Full Package consulting service is designed to support applications to both US and UK universities, including Ivy League schools, Oxbridge Law, and other top US institutions. We are familiar with the nuances of each system and can guide you through the specific requirements and expectations." 
          },
        ],
      };
    
      const renderFAQSection = (sectionTitle, questions) => (
      <>
        <MiniHeader>{sectionTitle}</MiniHeader>
        {questions.map((item, index) => {
          const globalIndex = `${sectionTitle}-${index}`;
          return (
            <FAQItem key={globalIndex}>
              <QuestionWrapper onClick={() => toggleQuestion(globalIndex)}>
                <Question isActive={activeIndex === globalIndex}>
                  {item.question}
                </Question>
                <Arrow isActive={activeIndex === globalIndex}>▼</Arrow>
              </QuestionWrapper>
              <Answer isVisible={activeIndex === globalIndex}>
                {item.answer}
              </Answer>
            </FAQItem>
          );
        })}
      </>
    );
    
    // Prepare the FAQ data for the schema
    const schemaFAQs = Object.values(faqData).flat().map(item => ({
      "@type": "Question",
      "name": item.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": item.answer
      }
    }));

    return (
      <FAQWrapper id="faq" ref={faqRef}>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": schemaFAQs
            })}
          </script>
        </Helmet>
        <Title>Frequently Asked Questions</Title>
        <FAQList>
          {renderFAQSection("Ad-Hoc Consultation", faqData.adHocConsulting)}
          {renderFAQSection("Scholarship Consultation", faqData.scholarshipConsulting)}
          {renderFAQSection("Full Package", faqData.fullPackage)}
        </FAQList>
      </FAQWrapper>
    );
  };

export default FAQ;