import React from 'react';
import styled from 'styled-components';
import { FaUserCog, FaTrophy, FaUniversity, FaBullseye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { HiCursorClick } from 'react-icons/hi';
import { keyframes } from 'styled-components';

const WhyUsWrapper = styled.section`
  padding: 1rem 1rem;
  background-color: white;
  color: black;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  color: #003366;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  justify-content: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background-color: ${props => props.isFlipped ? '#FFD700' : '#003366'};
  color: ${props => props.isFlipped ? '#003366' : '#FFD700'};
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 1.5rem;
  cursor: pointer;
  transition: transform 0.6s, background-color 0.6s, color 0.6s;
  transform-style: preserve-3d;
  transform: ${props => props.isFlipped ? 'rotateY(180deg)' : 'rotateY(0)'};
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const CardBack = styled(CardContent)`
  transform: rotateY(180deg);
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: ${props => props.isFlipped ? '#003366' : '#FFD700'};
  margin-bottom: 0.3rem;
`;

const CardTitle = styled.h3`
  font-size: 1.25rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;

  @media (max-width: 600px) {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }
`;

const CardDescription = styled.p`
  font-size: 0.9rem;
  line-height: 1.4;
  margin-top: 0.3rem;
  padding: 1rem;

  @media (max-width: 600px) {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }
`;

const fadeInOut = keyframes`
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
`;

const FlipIcon = styled(HiCursorClick)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: ${props => props.isFlipped ? '#FFD700' : '#FFD700'};
  animation: ${fadeInOut} 3s ease-in-out infinite;
  transition: color 0.6s, transform 0.6s;
  transform: ${props => props.isFlipped ? 'rotateY(180deg)' : 'rotateY(0)'};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const AboutButton = styled(Link)`
  background-color: #FFD700;
  color: #003366;
  text-decoration: none;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #FFC700;
    transform: translateY(-2px);
  }
`;

const WhyUsSection = () => {
  const [flippedCards, setFlippedCards] = React.useState({});

  const features = [
    {
      icon: FaUserCog,
      title: "Personalized Guidance",
      description: "Experience tailored mentorship with our mentor, who maintains a limited mentee roster to ensure focused, individualized attention for your academic journey."
    },
    {
      icon: FaTrophy,
      title: "Proven Track Record",
      description: "Benefit from over 6 years of specialized expertise in guiding students to top-tier US programs and prestigious UK law schools, with a history of successful admissions."
    },
    {
      icon: FaUniversity,
      title: "Ivy League Pedigree",
      description: "Learn from a mentor who's walked the path you aspire to, with degrees from the University of Cambridge and Harvard Law School, and a portfolio of offers from other world-class institutions."
    },
    {
      icon: FaBullseye,
      title: "Niche Mastery",
      description: "Gain unparalleled insights in liberal arts and Oxbridge law applications from our mentor, whose specialized knowledge in these fields is second to none."
    }
  ];
  

  const toggleCard = (index) => {
    setFlippedCards(prev => ({...prev, [index]: !prev[index]}));
  };

  return (
    <WhyUsWrapper id="why-us">
      <Container>
        <Title>Why Choose Us?</Title>
        <CardGrid>
          {features.map((feature, index) => (
            <Card key={index} isFlipped={flippedCards[index]} onClick={() => toggleCard(index)}>
              <CardContent>
                <IconWrapper isFlipped={false}>
                  <feature.icon />
                </IconWrapper>
                <CardTitle>{feature.title}</CardTitle>
              </CardContent>
              <CardBack>
                <IconWrapper isFlipped={true}>
                  <feature.icon />
                </IconWrapper>
                <CardTitle>{feature.title}</CardTitle>
                <CardDescription>{feature.description}</CardDescription>
              </CardBack>
              <FlipIcon isFlipped={flippedCards[index]} />
            </Card>
          ))}
        </CardGrid>
        <ButtonWrapper>
          <AboutButton to="/about-us">Learn More About Our Expert Mentor</AboutButton>
        </ButtonWrapper>
      </Container>
    </WhyUsWrapper>
  );
};

export default WhyUsSection;
