import React, { useEffect, useRef } from 'react';
import { FaGlobeAmericas, FaGraduationCap, FaHandsHelping, FaHandHoldingHeart } from 'react-icons/fa';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";

const WaiverWrapper = styled.section`
  padding: 4rem 1rem;
  background-color: #F5F5F5;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23003366' fill-opacity='0.02' fill-rule='evenodd'/%3E%3C/svg%3E");
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

const LeftColumn = styled.div`
  flex: 1;
  min-width: 300px;
`;

const RightColumn = styled.div`
  flex: 1;
  min-width: 300px;
`;

const Title = styled.h2`
  color: #003366;
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const Subtitle1 = styled.p`
  color: #004f9e;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-style: italic;
`;
const Subtitle = styled.p`
  color: #000000;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const Subtitle3 = styled.p`
  color: #000000;
  font-size: 0.6rem;
  margin-bottom: 2rem;
`;

const Button = styled(Link)`
  background-color: #FFD700;
  color: #003366;
  text-decoration: none;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  margin-bottom: 3rem;
  
  &:hover {
    background-color: #FFC700;
  }
`;


const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

const IconWrapper = styled.div`
  font-size: 1.5rem;
  color: #e6c100;
  margin-top: 0.25rem;
  transform: translateY(15px); 
`;

const FeatureContent = styled.div`
  flex: 1;
`;

const FeatureTitle = styled.h3`
  color: #003366;
  margin-bottom: 0.5rem;
  font-size: 1.2rem; 
  line-height: 1.2; 
`;

const FeatureDescription = styled.p`
  color: #666;
  font-size: 1rem; 
`;

const WaiverProgramme = ({ onView }) => {
  const waiverRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onView();
          ReactGA.event({
            category: "Section View",
            action: "View",
            label: "Waiver Programme"
          });
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (waiverRef.current) {
      observer.observe(waiverRef.current);
    }

    return () => {
      if (waiverRef.current) {
        observer.unobserve(waiverRef.current);
      }
    };
  }, [onView]);

  const handleEnquireClick = () => {
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "Waiver Programme Enquire"
    });
  };

  const features = [
    {
      icon: FaHandsHelping,
      title: "Low Household Income",
      description: "There is no national poverty line in Singapore. But those with low income may face significant struggles in the application process, for various reasons. Reach out if your monthly household income is below or around S$1,500."
    },
    {
      icon: FaGraduationCap,
      title: "First Generation Applying for University",
      description: "Those who go with guidance find it easier than those who are figuring everything out for the first time. If you are the first in your nuclear family applying for universities, please reach out."
    },
    {
        icon: FaGlobeAmericas,
        title: "Refugee or Marginalized Immigrant",
        description: "Being in a new place and culture is hard. Adjusting well and overcoming the odds to excel is even harder. Reach out if you encountered signficiant obstacles in your early years."
      },
     {
      icon: FaHandHoldingHeart,
      title: "Others",
      description: "There are many reasons why one may be considered to be from a particularly disadvantaged background. If you feel that you do not fall into any of the categories above, but nonetheless have a story to tell, please reach out."
    },
  ];

  return (
    <WaiverWrapper id="waiver-programme" ref={waiverRef}>
      <Container>
        <LeftColumn>
          <Title>Waiver Programme</Title>
          <Subtitle1>
            "The delicate balance of mentoring someone is not creating them in your own image, but giving them the opportunity to create themselves."

            - Steven Spielberg
          </Subtitle1>
          <Subtitle>We believe in the power of mentorship. If you come from a particularly disadvantaged background, we offer the full package, free of cost.*</Subtitle>
          <Subtitle3>* Subject to availability of mentors.</Subtitle3>
          <Button to="/enquire" onClick={handleEnquireClick}>Enquire Now</Button>
        </LeftColumn>
        <RightColumn>
          <FeaturesGrid>
            {features.map((feature, index) => (
              <FeatureItem 
                key={index}
                onClick={() => {
                  ReactGA.event({
                    category: "Feature",
                    action: "Click",
                    label: feature.title
                  });
                }}
              >
                <IconWrapper>
                  <feature.icon />
                </IconWrapper>
                <FeatureContent>
                  <FeatureTitle>{feature.title}</FeatureTitle>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                </FeatureContent>
              </FeatureItem>
            ))}
          </FeaturesGrid>
        </RightColumn>
      </Container>
    </WaiverWrapper>
  );
};

export default WaiverProgramme;