import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { FaHandshake, FaBuilding, FaCog, FaFileContract, FaUserCheck, FaUserClock, FaCopyright, FaShieldAlt, FaUserSecret, FaGavel, FaBalanceScale, FaEdit, FaBolt, FaTimesCircle, FaUserShield, FaPuzzlePiece, FaFileSignature, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const PageWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem 2rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #003366;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  position: relative;
  
  &:after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: #FFD700;
    margin: 1rem auto 0;
  }
`;

const Section = styled.section`
  margin-bottom: 3rem;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const SectionTitle = styled.h2`
  color: #003366;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
`;

const Icon = styled.span`
  margin-right: 10px;
  color: #FFD700;
`;

const Paragraph = styled.p`
  line-height: 1.8;
  margin-bottom: 1rem;
  color: #333;
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 1.5rem;
`;

const ListItem = styled.li`
  position: relative;
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  font-size: 1.05rem;
  line-height: 1.9;

  &:before {
    content: "•";
    color: #003366;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    top: 0;
    line-height: 1.6;
  }
`;

const TermsAndConditions = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>Terms and Conditions | Khan Admissions</title>
        <meta name="description" content="Review our terms and conditions to understand the rules and guidelines for using Khan Admissions' services and website." />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Terms and Conditions",
              "description": "Terms and conditions for using Khan Admissions services and website.",
              "url": "https://khanadmissions.com/terms-and-conditions"
            }
          `}
        </script>
      </Helmet>

  <Title>Terms and Conditions</Title>

  <Section>
    <SectionTitle>
      <Icon><FaHandshake /></Icon>
      1. Introduction
    </SectionTitle>
    <Paragraph>Welcome to Khan Admissions Private Limited. These Terms and Conditions ("Terms") govern your use of our website, services, and platforms ("Services"). By using our Services, you agree to comply with these Terms. If you do not agree with these Terms, please do not use our Services.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaBuilding /></Icon>
      2. Company Information
    </SectionTitle>
    <Paragraph><strong>Company Name</strong>: Khan Admissions Private Limited</Paragraph>
    <Paragraph><strong>Registered Address</strong>: [Insert Registered Address Here], Singapore</Paragraph>
    <Paragraph><strong>Contact Email</strong>: hello@khanadmissions.com</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaCog /></Icon>
      3. Services
    </SectionTitle>
    <Paragraph>Khan Admissions Private Limited provides the following services:</Paragraph>
    <List>
      <ListItem><strong>Ad Hoc Consultation (Hourly)</strong>: One-time or occasional consultations on specific topics related to admissions or scholarships.</ListItem>
      <ListItem><strong>Scholarship Consultation (Hourly)</strong>: Focused guidance on scholarship applications and strategies.</ListItem>
      <ListItem><strong>Full Package</strong>: Comprehensive admissions support, including application review, essay editing, and strategic planning. The exact scope will be defined in a separate agreement.</ListItem>
    </List>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaFileContract /></Icon>
      4. Terms of Service
    </SectionTitle>
    <List>
      <ListItem><strong>No Guarantees</strong>: We do not guarantee admission to any educational institution or scholarship award. We are not liable for any representations or expectations regarding admissions or scholarships.</ListItem>
      <ListItem><strong>Refund Policy</strong>: No refunds are provided once services commence, unless at the sole discretion of the company in exceptional circumstances.</ListItem>
      <ListItem><strong>Payment Terms</strong>: Payment must be made in full before the commencement of services, unless otherwise agreed upon between the company and the client.</ListItem>
    </List>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaUserCheck /></Icon>
      5. User Obligations
    </SectionTitle>
    <Paragraph>Users of our Services must comply with all applicable laws and regulations. Any illegal activities or violations of these Terms may result in the termination of services without refund.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaUserClock /></Icon>
      6. Age Restrictions
    </SectionTitle>
    <Paragraph>Our Services are intended for individuals who are at least 13 years old. By using our Services, you represent that you are at least 13 years old. If you are under 13 years old, please do not use our Services. We do not knowingly collect personal data from children under 13 without verifiable parental consent, in compliance with the Children's Online Privacy Protection Act (COPPA).</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaCopyright /></Icon>
      7. Intellectual Property
    </SectionTitle>
    <Paragraph>All content on our website and materials provided during the Services are the property of Khan Admissions Private Limited. Unauthorized use of our materials is prohibited.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaShieldAlt /></Icon>
      8. Limitation of Liability
    </SectionTitle>
    <Paragraph>Khan Admissions Private Limited is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our Services. This includes, but is not limited to, loss of data, loss of profits, or any other financial loss.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaUserSecret /></Icon>
      9. Privacy and Confidentiality
    </SectionTitle>
    <Paragraph>
  Our <Link to="/privacy-policy" aria-label="View Privacy Policy">Privacy Policy</Link> and <Link to="/data-protection" aria-label="View Data Protection Policy">Data Protection Notice</Link> outline how we handle your personal data. By using our Services, you agree to the terms outlined in these policies. 
  We maintain strict confidentiality of all client information and will not disclose any personal or application-related 
  information without explicit consent, except as required by law.
</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaGavel /></Icon>
      10. Governing Law
    </SectionTitle>
    <Paragraph>These Terms are governed by and construed in accordance with the laws of Singapore. Any disputes arising from or in connection with these Terms will be subject to the exclusive jurisdiction of the courts of Singapore.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaBalanceScale /></Icon>
      11. Dispute Resolution
    </SectionTitle>
    <Paragraph>In the event of any disputes, we recommend the following steps:</Paragraph>
    <List>
      <ListItem><strong>Mediation</strong>: The parties will first attempt to resolve the dispute through mediation. A neutral third-party mediator will be appointed to facilitate the resolution.</ListItem>
      <ListItem><strong>Arbitration</strong>: If mediation fails, the dispute will be resolved through arbitration in accordance with the rules of the Singapore International Arbitration Centre (SIAC). The arbitration will be conducted in Singapore and the language of arbitration will be English.</ListItem>
    </List>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaEdit /></Icon>
      12. Changes to Terms
    </SectionTitle>
    <Paragraph>We may update these Terms from time to time. The updated Terms will be posted on our website. It is your responsibility to review the Terms periodically. Continued use of our Services constitutes acceptance of the updated Terms.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaBolt /></Icon>
      13. Force Majeure
    </SectionTitle>
    <Paragraph>Neither party shall be liable for any failure or delay in performing their obligations where such failure or delay results from any cause that is beyond the reasonable control of that party. Such causes include, but are not limited to: power failure, Internet service provider failure, industrial action, civil unrest, fire, flood, storms, earthquakes, acts of terrorism, acts of war, governmental action or any other event that is beyond the control of the party in question.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaTimesCircle /></Icon>
      14. Termination
    </SectionTitle>
    <Paragraph>Either party may terminate this agreement with 30 days' written notice. Khan Admissions Private Limited reserves the right to terminate services immediately if the client violates these Terms or engages in any unethical or illegal activities.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaUserShield /></Icon>
      15. Indemnification
    </SectionTitle>
    <Paragraph>You agree to indemnify, defend, and hold harmless Khan Admissions Private Limited, its officers, directors, employees, agents, and affiliates from and against all losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from any violation of these Terms or any activity related to your account.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaPuzzlePiece /></Icon>
      16. Severability
    </SectionTitle>
    <Paragraph>If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaFileSignature /></Icon>
      17. Entire Agreement
    </SectionTitle>
    <Paragraph>These Terms constitute the entire agreement between Khan Admissions Private Limited and the user in relation to your use of our Services.</Paragraph>
  </Section>

  <Section>
    <SectionTitle>
      <Icon><FaEnvelope /></Icon>
      18. Contact Information
    </SectionTitle>
    <Paragraph>If you have any questions or need further information about these Terms, please contact us at hello@khanadmissions.com.</Paragraph>
    <Paragraph>Last updated: 14 July 2024</Paragraph>
  </Section>
</PageWrapper>
  );
};

export default TermsAndConditions;