import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import TempBanner from '../components/TempBanner';
import HeroSection from '../components/HeroSection';
import CarouselSection from '../components/CarouselSection';
import ServicesOffered from '../components/ServicesOffered';
import WhyUs from '../components/WhyUs';
import WaiverProgramme from '../components/WaiverProgramme';
import FAQ from '../components/FAQ';
import EndBanner from '../components/EndBanner';
import ReactGA from 'react-ga4';

const LandingPage = () => {
  const trackSectionView = (sectionName) => {
    ReactGA.event({
      category: "Section View",
      action: "View",
      label: sectionName
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage = Math.round((window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100);
      if (scrollPercentage === 25 || scrollPercentage === 50 || scrollPercentage === 75 || scrollPercentage === 100) {
        ReactGA.event({
          category: "Scroll Depth",
          action: "Scroll",
          label: `Scrolled ${scrollPercentage}%`
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <Helmet>
        <title>Khan Admissions | Expert University Admissions Guidance</title>
        <meta name="description" content="Khan Admissions provides expert mentorship and guidance for students aspiring to attend top universities worldwide. Get personalized support for your academic journey." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Khan Admissions",
              "url": "https://khanadmissions.com",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://khanadmissions.com/search?q={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
      </Helmet>
      <TempBanner />
      <HeroSection onView={() => trackSectionView("Hero Section")} />
      <CarouselSection onView={() => trackSectionView("Carousel Section")} />
      <ServicesOffered onView={() => trackSectionView("Services Offered")} />
      <WhyUs onView={() => trackSectionView("Why Us")} />
      <WaiverProgramme onView={() => trackSectionView("Waiver Programme")} />
      <FAQ onView={() => trackSectionView("FAQ")} />
      <EndBanner onView={() => trackSectionView("End Banner")} />
    </>
  );
};

export default LandingPage;
