import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { FaGraduationCap, FaTrophy, FaLightbulb } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";
import heroImageCompressed from "../assets/azferwidecompressed.webp"
import heroImage from "../assets/azferwide.webp"

const Container = styled(motion.div)`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  background-color: #f8f9fa;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23003366' fill-opacity='0.1' d='M0,96L48,112C96,128,192,160,288,186.7C384,213,480,235,576,213.3C672,192,768,128,864,128C960,128,1056,192,1152,213.3C1248,235,1344,213,1392,202.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% auto;
  background-attachment: fixed;
`;

const HeroSection = styled.div`
  position: relative;
  width: 100%;
  height: 91vh;
  overflow: hidden;

`;

const HeroImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;

  @media (max-width: 768px) {
    object-fit: cover;
    object-position: center;
  }
`;

const Title = styled.h1`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  color: white;
  font-size: 3.5rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.7);

  span {
    display: block;
    font-size: 2rem;
    font-weight: 400;
    margin-top: 0.5rem;
  }

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 3px;
    background-color: #FFD700;
    margin-top: 0.5rem;
  }

  @media (max-width: 768px) {
    position: static;
    font-size: 2rem;
    margin-bottom: 1rem;
    
    span {
      font-size: 1.2rem;
    }

    &::after {
      width: 60px;
      height: 2px;
    }
  }
`;

const ScrollIndicator = styled(motion.div)`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  text-align: center;
  color: white;
  cursor: pointer;

  @media (max-width: 768px) {
    position: static;
    text-align: left;
    margin-top: 1rem;
  }
`;

const MobileHeaderContainer = styled.div`
  @media (max-width: 768px) {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }
`;

const ScrollText = styled.span`
  display: block;
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
`;

const ScrollArrow = styled(motion.svg)`
  width: 30px;
  height: 30px;
  fill: #FFD700;
`;

const Content = styled(motion.div)`
  padding: 2rem 2rem;
  max-width: 800px;
  margin: 2rem auto;
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23003366' fill-opacity='0.02' fill-rule='evenodd'/%3E%3C/svg%3E");
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 2rem 1.5rem;
  }
`;

const ContentSection = styled(motion.div)`
  margin-bottom: 2rem;
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #003366;
  border-bottom: 2px solid #FFD700;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

const TextContent = styled.div`
  p {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    line-height: 1.8;
    color: #444;
  }
`;

const PullQuote = styled.blockquote`
  font-size: 1.5rem;
  font-style: italic;
  color: #003366;
  border-left: 4px solid #FFD700;
  padding-left: 1rem;
  margin: 2rem 0;
`;

const EnquireButton = styled(Link)`
  background-color: #FFD700;
  color: #003366;
  text-decoration: none;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  display: inline-block;
  
  &:hover {
    background-color: #FFC700;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const AboutUs = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const contentRef = useRef(null);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }

    const img = new Image();
    img.src = heroImage;
    img.onload = () => setImageLoaded(true);
  }, [controls, inView]);

  const fadeInVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const staggerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.6,
      },
    }),
  };

  const handleScrollClick = () => {
    const headerHeight = document.querySelector('header').offsetHeight;
    const yOffset = -headerHeight;
    const element = contentRef.current;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  };

  const handleEnquireClick = () => {
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "About Us Enquire Now"
    });
  };

  return (
    <Container
      initial={{ backgroundPositionY: "0%" }}
      animate={{ backgroundPositionY: "100%" }}
      transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
    >
      <Helmet>
        <title>About Azfer A. Khan | Khan Admissions</title>
        <meta name="description" content="Learn about Azfer A. Khan, your dedicated mentor at Khan Admissions. Discover his educational journey, experiences, and aspirations for guiding students to top universities." />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, shrink-to-fit=no, user-scalable=no, "></meta>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Person",
              "name": "Azfer A. Khan",
              "jobTitle": "Mentor",
              "affiliation": "Khan Admissions",
              "alumniOf": [
                {
                  "@type": "CollegeOrUniversity",
                  "name": "University of Cambridge"
                },
                {
                  "@type": "CollegeOrUniversity",
                  "name": "Harvard Law School"
                }
              ],
              "description": "Azfer A. Khan is a dedicated mentor at Khan Admissions, guiding students through their academic journey to top universities worldwide."
            }
          `}
        </script>
      </Helmet>

      <HeroSection>
        <HeroImage
          src={heroImageCompressed}
          alt="Azfer A. Khan"
          style={{ opacity: imageLoaded ? 0 : 1 }}
        />
        <HeroImage
          src={heroImage}
          alt="Azfer A. Khan"
          style={{ opacity: imageLoaded ? 1 : 0 }}
          initial={{ scale: 1.05 }}
          animate={{ 
            scale: 1.1,
            x: [0, 10, 0, -10, 0],
            y: [0, -10, 0, 10, 0]
          }}
          transition={{ 
            scale: {
              duration: 10,
              repeat: Infinity,
              repeatType: "reverse"
            },
            x: {
              duration: 20,
              repeat: Infinity,
              repeatType: "loop"
            },
            y: {
              duration: 20,
              repeat: Infinity,
              repeatType: "loop"
            }
          }}
        />
        <MobileHeaderContainer>
        <Title>Azfer A. Khan <span>Your Dedicated Mentor</span></Title>
        <ScrollIndicator onClick={handleScrollClick}>
          <ScrollText>Discover More</ScrollText>
          <ScrollArrow
            viewBox="0 0 24 24"
            animate={{
              y: [0, 10, 0],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
          </ScrollArrow>
        </ScrollIndicator>
        </MobileHeaderContainer>
      </HeroSection>
      <Content
        ref={(el) => {
          ref(el);
          contentRef.current = el;
        }}
        initial="hidden"
        animate={controls}
        variants={fadeInVariants}
      >
        <ContentSection variants={staggerVariants} custom={0}>
          <Subtitle><FaGraduationCap /> Educational Journey</Subtitle>
          <ContentSection variants={staggerVariants} custom={1}>
          <PullQuote>"Education is not just about grades; it's about growth, understanding, and using knowledge to make a difference."</PullQuote>
        </ContentSection>
          <TextContent>
            <p>Azfer's passion for law and justice led him to the University of Cambridge, where he immersed himself in legal studies. His dedication and hard work were recognized with a starred first in Law (also known as a first with distinction - a rare honour awarded for exceptional academic performance), an achievement he attributes to the support of his professors, peers and family.</p>
            <p>Driven by a thirst for knowledge, Azfer continued his education at Harvard Law School. There, he embraced new challenges and perspectives, earning recognition as a Dean's Scholar in Jurisprudence. This experience broadened his understanding of global legal systems and reinforced his commitment to using law as a tool for positive change.</p>
          </TextContent>
        </ContentSection>

        <ContentSection variants={staggerVariants} custom={3}>
          <Subtitle><FaTrophy /> Experiences and Contributions</Subtitle>
          <ContentSection variants={staggerVariants} custom={1}>
          <PullQuote>"The greatest good you can do for another is not just to share your riches, but to reveal to him his own."</PullQuote>
        </ContentSection>
          <TextContent>
            <p>Since 2018, Azfer has found joy in sharing his knowledge the younger generation. He believes in the power of mentorship to unlock potential and is grateful for the opportunity to guide students through their academic and career paths.</p>
            <p>Azfer's commitment to public service led him to join the Singapore Police Force, under the auspices of the Singapore Police Force Scholarship, where he contributed to the fight against cybercrimes and scams. This role taught him valuable lessons about the practical application of law and the importance of protecting vulnerable individuals in our digital age.</p>
            <p>During his time with the public service, Azfer mentored a number of students aspiring for an Oxbridge law and an Ivy League education.</p>
          </TextContent>
        </ContentSection>

        <ContentSection variants={staggerVariants} custom={5}>
          <Subtitle><FaLightbulb /> Aspirations for the Future</Subtitle>
          <ContentSection variants={staggerVariants} custom={6}>
          <PullQuote>"Success is not just about personal achievements, but about lifting others as we climb."</PullQuote>
          </ContentSection>
          <TextContent>
            <p>Azfer dreams of a world where quality education is within reach for everyone. He believes that with the right guidance and support, each individual can overcome obstacles and achieve their goals, no matter their background.</p>
            <p>Committed to continuous learning, Azfer strives to improve his mentoring approach. He values the unique perspectives of each student and aims to create a supportive environment where ideas can flourish and potential can be realized.</p>
          </TextContent>
        </ContentSection>

        <ButtonWrapper>
          <EnquireButton to="/enquire" onClick={handleEnquireClick}>
            Enquire Now
          </EnquireButton>
        </ButtonWrapper>

      </Content>
    </Container>
  );
};

export default AboutUs;