import React from 'react';
import styled, { keyframes } from 'styled-components';

// Import your logo images
import harvardLogo from '../assets/harvard.png';
import oxfordLogo from '../assets/oxford.png';
import cambridgeLogo from '../assets/cambridge.png';

const CarouselWrapper = styled.section`
  padding: 0.5rem 1rem;
  text-align: center;
  overflow: hidden;
`;

const Title = styled.h2`
  color: #003366;
  margin-bottom: 1rem;
  text-align: center;
`;

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const CarouselContent = styled.div`
  display: flex;
  width: 200%; // Double the width to allow for looping
  animation: ${scroll} 35s linear infinite;

  @media (max-width: 768px) {
    animation: ${scroll} 15s linear infinite; 
  }
`;

const CarouselItem = styled.div`
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  margin: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  max-width: 115%;
  max-height: 100%;
  object-fit: contain;
`;

const CarouselSection = () => {
  const logos = [
    { src: harvardLogo, alt: 'Harvard University' },
    { src: oxfordLogo, alt: 'University of Oxford' },
    { src: cambridgeLogo, alt: 'University of Cambridge' },
  ];

  // Duplicate the logos array to create a seamless loop
  const allLogos = [...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos];

  return (
    <CarouselWrapper>
      <Title>Mentees have secured offers from:</Title>
      <CarouselContent>
        {allLogos.map((logo, index) => (
          <CarouselItem key={index}>
            <Logo src={logo.src} alt={logo.alt} />
          </CarouselItem>
        ))}
      </CarouselContent>
    </CarouselWrapper>
  );
};

export default CarouselSection;